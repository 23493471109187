"use strict";

document.addEventListener( 'DOMContentLoaded', function () {

    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * language switcher
    // *
    // *

    const langButton = document.querySelector('.js-language');
    const langDropdown = document.querySelector('.js-dropdown');

    if (langButton && langDropdown !== null) {
        langButton.addEventListener('click', () => {
            langDropdown.classList.toggle('lang__dropdown--show');
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *  open and close info layer
    // *
    // *

    const asideLink = document.querySelector(".js-aside-link");
    const asideClose = document.querySelector(".js-aside-close");
    const aside = document.querySelector(".js-aside");

    if (asideLink !== null) {
        asideLink.onclick = function(e) {
            aside.classList.toggle('open'); 
        }
    }

    if (asideClose !== null) {
        asideClose.onclick = function(e) {
            aside.classList.toggle('open'); 
        }
    }

    

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *  open and close mobile menu layer
    // *  by click
    // *

    const menuLink = document.querySelector(".js-menu-link");
    const menu = document.querySelector(".js-mobile-menu");

    if (menuLink && menu !== null) {
        menuLink.onclick = function(e) {
            menu.classList.toggle('open'); 
        }
    }

    const menuclose = document.querySelector(".js-mobile-menu-close");
    if (menuclose !== null) {
        menuclose.onclick = function(e) {
            menu.classList.remove('open'); 
        }
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *  open and close all layer
    // *  by keydown
    // *

    document.addEventListener('keydown', function(e) {
        if (e.key === "Escape") {
            aside.classList.remove('open'); 
            menu.classList.remove('open'); 
            langDropdown.classList.remove('lang__dropdown--show');
        }
    });

    document.addEventListener('keydown', function(e) {
        aside.classList.remove("open");  
    });
    


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * fullpage.js
    // *
    // *

    let myFullpage = new fullpage('#fullpage', {
        licenseKey: '5ZIK9-30T58-K32E6-4MVJ9-OPPNO',
        credits: { 
            enabled: false,
        }, 
        recordHistory: true,
        menu: '#menu',
        scrollingSpeed: 1000,
        loopTop: false,
        loopBottom: false,
        scrollOverflow: true,
        controlArrows: true,
        controlArrowsHTML: [
            '<svg><use xlink:href="assets/website/img/sprite.svg#angle-left-2"></use></svg>',
            '<svg><use xlink:href="assets/website/img/sprite.svg#angle-right-2"></use></svg>',
        ],
        lazyLoading: false,
        
        //fadingEffectKey: 'NjBZbXhoYm1NdFpYUXRibTlpYkdVdWJIVT1BSV9RYm5abUZrYVc1blJXWm1aV04wOHZF',
        //fadingEffect: true,
        //fadingEffect:'slides',
        //fadingEffect:'sections',
        
        parallaxKey: 'QWpZbXhoYm1NdFpYUXRibTlpYkdVdWJIVT1ucF9FM2ZjR0Z5WVd4c1lYZz00U24=',
        parallax: false,
        parallaxOptions: {
            type: 'reveal',
            percentage: 62,
            property: 'translate'
        },
        
        resetSlidersKey: 'cGxZbXhoYm1NdFpYUXRibTlpYkdVdWJIVT1yel9mN0ljbVZ6WlhSVGJHbGtaWEp6M2Qx',
        resetSliders: true,
        
        scrollHorizontally: true,
        scrollHorizontallyKey: ['cjZZbXhoYm1NdFpYUXRibTlpYkdVdWJIVT1Jbl9ZeHZjMk55YjJ4c1NHOXlhWHB2Ym5SaGJHeDVyeFM='],
        
        afterLoad: function(origin, destination, direction, trigger) {
            setCounter();
        },
        onLeave: function(origin, destination, direction, trigger){
            menu.classList.remove('open'); 
            setTimeout(changeColorSection,400);
        },

        afterSlideLoad: function(origin, destination, direction, trigger) {
            setCounter();
            setTimeout(changeColorSlide,1);
        },

        onSlideLeave: function( section, origin, destination, direction, trigger) {
            menu.classList.remove('open'); 
            setTimeout(changeColorSlide,1);
        }
    });

    function setCounter(){
        let activeSlide = fullpage_api.getActiveSlide();

        if (activeSlide !== null) {
            let sectionItem = fullpage_api.getActiveSection().item;
            let numSlides = sectionItem.querySelectorAll(".fp-slide").length;
            let counter = document.querySelectorAll(".counter");
            let slideNumber = activeSlide.index + 1;

            for (let i = 0; i < counter.length; i++) {
                counter[i].innerHTML = slideNumber + " / " + numSlides;
            }
        }
    } 

    function changeColorSection() {
        let activeSection = fullpage_api.getActiveSection();

        if (activeSection.item.classList.contains("bgPrimary")) {
            document.body.classList.remove('fp-viewing-light');
            document.body.classList.add('fp-viewing-dark');
        } 
        if (activeSection.item.classList.contains("bgSecondary")) {
            document.body.classList.remove('fp-viewing-light');
            document.body.classList.add('fp-viewing-dark');
        } 
        if (activeSection.item.classList.contains("bgTertiary")) {
            document.body.classList.remove('fp-viewing-dark');
            document.body.classList.add('fp-viewing-light');
        }
    }

    function changeColorSlide() {
        let myActiveSection = fullpage_api.getActiveSection();

        if (myActiveSection.activeSlide.item.classList.contains("bgPrimary")) {
            document.body.classList.remove('fp-viewing-light');
            document.body.classList.add('fp-viewing-dark');
        } 
        if (myActiveSection.activeSlide.item.classList.contains("bgSecondary")) {
            document.body.classList.remove('fp-viewing-light');
            document.body.classList.add('fp-viewing-dark');
        } 
        if (myActiveSection.activeSlide.item.classList.contains("bgTertiary")) {
            document.body.classList.remove('fp-viewing-dark');
            document.body.classList.add('fp-viewing-light');
        }
    }
});